@import "./config.scss";
@import "material.scss";
@import "./btn.scss";
@import "datepicker.scss";
@import "./form.scss";  
@import "./modulos.scss";  
@import "./page.scss";  
@import "./galeria.scss";    
@import "./modais.scss";   
@import "./footer.scss";   
@import "./template.scss";  
@import "animateCustom.scss"; 

html{
    -webkit-text-size-adjust: 100% !important;  
}
html, body { height: 100%; }
body{
    font-family: map-get($fonts,two);
    font-size: 12px;
    font-weight: 400;
    color: #000;
}
h1,h2,h3,h4{
    font-family: map-get($fonts,one);
    color: #373737;
}
.linha-white{
    height: 5px;
    width: 100%;
    background: #FFF;
    display: table;
    margin: 20px 0;
}
/** grid **/
.wp-layout-grid{
    display: flex !important;
    overflow: auto;
    //margin: -20px -20px -20px -30px;
    margin: -20px -10px -20px -20px; 
    padding: 20px 0 0 20px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    scroll-behavior: smooth; 
}
.wp-layout-item{
    margin-right: 20px; 
    -webkit-flex-basis: 290px;
    -ms-flex-preferred-size: 290px;
    flex-basis: 290px; 
    flex: 0 0 290px;  
    margin-bottom: 5px;
    -webkit-box-flex: 0;
}
.wp-layout-grid::-webkit-scrollbar {
    background-color: transparent !important;
}
a,button{
    outline: none !important;
}
.icon-orange{
    background: map-get($colors,six);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin: 0 auto; 
}
.icon-orange i{
    font-size: 20px;
    color: map-get($colors,one);
}
.header.fixed .content-menu a,.header.template-two .content-menu a{
    color: map-get($colors,one) !important;
}  
.module-products .module-title h2{
    font-size: 40px;
    color: #373737;
    font-weight: 600;
    text-align: left;
}
.module-products .module-title h3{ 
    color: #313131;
    font-weight: 400;   
}
article p{
    margin: 0 0 20px 0;
}

@media (min-width: 1200px){
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1280px;
    }
}
@media (max-width: 767px){

    body{
        padding-top: 60px;
    }

}