@import "./config.scss";

footer h3.modulo-title{
    color: map-get($colors,one);
    font-size: 14px;
    font-family: map-get($fonts,three);
    margin: 0 0 10px 0;
    font-weight: 900;
}

@media (max-width: 959px){


}
@media (max-width: 767px){

    footer .modulo{
        margin: 0 0 20px 0 !important;
    }

}