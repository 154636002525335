@import "config.scss";

.btn-blue,.btn-green,.btn-one,.btn-white,.btn-two,.btn-three,.btn-blue,.btn-white-blue,.btn-green-white,.btn-grey,.btn-red{
    background: map-get($colors,five);
    display: flex;
    padding: 18px 25px;
    border-radius: 5px;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    font-weight: 600;
    font-size: 12px;
    position: relative;
    border: 0;
    cursor: pointer;
    -webkit-transition: background 500ms ease;
    -moz-transition: background 500ms ease;
    -ms-transition: background 500ms ease;
    -o-transition: background 500ms ease;
    transition: background 500ms ease;
}
.btn-blue{  
    background: map-get($colors,one);
    color: #FFF;
}
.btn-one{  
    background: transparent;
    color: #373737;
    border-radius: 10px;
    border: 1px solid #373737;
}
.btn-one:hover{
    background: map-get($colors,two);
    color: #FFF;
}
.btn-two{  
    background: map-get($colors,two);
    color: #fff;
}
.btn-three{  
    background: #FFF;
    color: map-get($colors,one);
    border: 1px solid #888888;
}
.icon-left .icon{
    margin: 0 7px 0 0;
}
.icon-right .icon{
    margin: 0 0 0 7px;
}
.btn-icon .icon{
    margin: 0 5px 0 0;
}
/** btn custom **/
.btn-custom{
    background: #FFF;
    border-radius: 30px;
    display: flex;
    border: 0;
    padding: 20px 30px;
    -webkit-box-shadow: 0 0 10px 0 #D6D6D6;
    box-shadow: 0 0 10px 0 #D6D6D6;
    cursor: pointer;
    border: 1px solid #c4c4c4;
}
.btn-icon i{
    font-size: 13px;
    margin: 0 5px 0 0;
}


