@import "config.scss";

mat-form-field{
    width: 100%;
    display: table;
}
.mat-select-arrow{
    color: map-get($colors,one);
}
.mat-form-field-infix{
    border-top: .2em solid transparent;
}
.mat-form-field-ripple,
.mat-primary .mat-option.mat-selected,
.mat-select:focus .mat-select-underline{
    background: map-get($colors,two) !important; 
}
.mat-radio-button.mat-accent .mat-radio-inner-circle{
    background-color: map-get($colors,two) !important; 
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
    border-color: map-get($colors,two) !important; 
}
.mat-primary .mat-option.mat-selected{
    color: #FFF !important; 
}
.mat-option-text{
    font-size: 12px;
}
.mat-form-field-infix{
    padding: 5px 0 1px !important; 
}
.mat-select-trigger{
    /*padding: 16px 0 !important;*/
}
.mat-focused label,
.mat-select:focus .mat-select-placeholder{
    color: #334960 !important; 
}
.mat-form-field{
	width: 100% !important;
}
.mat-input-wrapper .mat-input-info{
    font-size: 10px;
    position: absolute;
    left: 0;
    top: 35px;
    color: #757575;
}
.mat-select-trigger{
    height: 28px !important;
}
.mat-select-placeholder,
.mat-select-value-text{
    font-size: 14px;
}
/** Material Custom **/
.md-select-custom label{
    margin: 0;
}
.md-select-custom .mat-select{
    padding-top: 0;
}
.md-select-custom .mat-select-trigger{
    height: 30px !important;
}
.md-select-custom.not-placeholder .mat-floating-placeholder{
    font-size: 0;
}
.md-select-custom .mat-select-trigger{
    min-width: 100%;
}
.mat-select{
    border-bottom: 1px solid #000;
}
.no-padding .mat-dialog-container{
    padding: 0 !important;
}
.mat-tab-label{
    font-size: 12px !important; 
}