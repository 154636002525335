@import "config.scss";

.page{
    padding: 10px 0 100px 0; 
    background: #ffffff;
    min-height: 500px;
}
.page .page-title{
    position: relative;
    display: table;
    width: 100%;
    text-align: left;
}
.page-title-h1{
    font-size: 40px;
    color: #373737;
    font-weight: 600;
}
.page-title-h2{
    font-size: 18px;
}
.page .descricao article{
    text-align: justify;
    font-size: 14px;
    line-height: 25px;
}
.page .page-title .linha-left{
    left: 0;
}
.page .page-title .linha-right{
    right: 0;
}
/** SOBRE **/
.page-sobre .descricao article h3{
    color: #222222;
    font-family: map-get($fonts,twenty-two);
    font-size: 22px;
    margin: 40px 0;
}
/** SERVIÇO **/
.section-descricao-content .descricao p{
    margin: 0 0 10px 0;
}
.section.descricao article strong{
    font-weight: bold;
}
.produto-relacionado-slider .slick-dots li{
    margin: 0 3px;
    width: 10px;
    height: 10px;
    cursor: pointer;
}
.produto-relacionado-slider .slick-dots li button:before{
    font-size: 12px;
}
.produto-relacionado-slider .slick-dots{
    margin: 0 0 20px 0;
}
.produto-relacionado-slider .slick-dots li button{
    background: #BDCBDB;
    font-size: 0;
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: rgba(255,255,255,0.81);
    border-radius: 100%;
}
.page-custom-title{
    color: map-get($colors,one);
    font-size: 36px;
    line-height: 36px;
    font-family: map-get($fonts,four);
}
