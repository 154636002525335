@import "config.scss";

.modulo-sobre .content-visao .visao strong{
    font-family: map-get($fonts,three); 
}
.modulo-sobre .descricao strong{
    font-family: map-get($fonts,one);
}
/** BANNER SLIDER SLICK **/
.banner-slider-slick .slick-dots,
.produto-slide-show .slick-dots,
.modulo-banner-destaque .slick-dots{
    bottom: 15px;
}
.modulo-banner-destaque .slick-dots{
    bottom: 20px !important;  
}
.banner-slider-slick .slick-dots li button,
.produto-slide-show .slick-dots li button,
.modulo-banner-destaque .slick-dots li button{  
    font-size: 0; 
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: rgba(255,255,255,0.81);
    border-radius: 100%;
}
.banner-slider-slick .slick-dots li,
.produto-slide-show .slick-dots li{
    width: 5px;
}
.banner-slider-slick .slick-dots li button:before,
.produto-slide-show .slick-dots li button:before{
    content: "" !important;
}
.banner-slider-slick .slick-dots .slick-active button,
.produto-slide-show .slick-dots .slick-active button{
    background: map-get($colors,two); 
}
.produto-slide-show .slick-dotted.slick-slider{
    margin-bottom: 0;
}
.produto-slide-show .produto-slide-show-nav{
    position: relative;
    margin: -7px 0 0 0;
}
.produto-slide-show-nav .item{
    cursor: pointer;
    opacity: 0.7;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.produto-slide-show-nav .slick-current .item{
    opacity: 1;
}

@media(max-width: 959px){

    .modulo-dicas .modulo-titulo h2 br,
    .modulo-dicas .modulo-titulo h3 br{
        display: none !important;
    }

}